<template>
    <div>
      <EventList :lang="lang" />
    </div>
  </template>
  
  <script>
  import EventList from '../components/EventList.vue';
  
  export default {
    components: {
      EventList
    },
    props: {
      lang: {
        type: String,
        default: 'eu'
      }
    }
  };
  </script>
  
  <style scoped>
  </style>
  