import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core';
import { provideApolloClient } from '@vue/apollo-composable';

// Usamos la variable de entorno definida en .env o .env.development
const httpLink = createHttpLink({
  uri: import.meta.env.VITE_API_URI, // Se ajusta automáticamente dependiendo del entorno
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

provideApolloClient(apolloClient);

export default apolloClient;
