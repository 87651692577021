<template>
  <div ref="filterContainer">
    <div class="dropdown" ref="dropdown">
      <button class="dropdown-toggle" 
              :class="{ 'selected': filtersApplied }" 
              @click="toggleDropdown">
        {{ $t('placeTypes.title') }} 
        <span v-if="!filtersApplied" class="show-filter" :class="{ 'rotated': isOpen }"> <img src="../assets/svg/down.svg" alt="Toggle Filter Icon"></span>
        <span v-if="filtersApplied" @click.stop="resetFilter" class="reset-btn"> <img src="../assets/svg/x.svg" alt="Reset Filter"></span>
    </button>
    <div class="dropdown-menu" :class="{ open: isOpen }" @click.self="isOpen = false" ref="dropdownMenu">
        <div>
          <input 
            type="checkbox" 
            id="all-placeTypes"
            :checked="allPlaceTypesSelected"
            @change="onAllPlaceTypesChange"
          />
          <label v-if="nroEvents > 0" for="all">{{ $t('all') }}</label>
        </div>
        <div v-if="placeTypes.length > 0">
          <div v-for="placeType in placeTypes" :key="placeType.id">
            <input 
              type="checkbox" 
              :id="placeType.id" 
              :value="placeType.id" 
              v-model="localSelectedPlaceTypes"
              @change="onPlaceTypeChange(placeType.id, $event.target.checked)"
            />
            <label :for="placeType.id">{{ placeType.name }} ({{ placeType.count }})</label>
          </div>
          <button @click="applyFilter" class="apply-button">
            {{ $t('apply') }}
          </button>
        </div>
        <div v-else>
          <p>{{ $t('placeTypes.notFound') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted, onUnmounted, nextTick} from 'vue';
import { filterState } from '../filterState'; 

export default {
  props: {
    selectedPlaceTypes: {
      type: Array,
      default: () => []
    },
    placeTypesResult: {
      type: Array,
      default: () => []
    },
    numberOfEvents: {
      type: Number
    }
  },
  setup(props, { emit }) {
    const placeTypes = ref([]);
    const localSelectedPlaceTypes = ref([...props.selectedPlaceTypes]);
    const filtersApplied = ref(false);
    const isOpen = ref(false);
    const dropdown = ref(null);
    const nroEvents = ref(props.numberOfEvents); 

    watch(
      () => props.placeTypesResult,
      (newResult) => {
        if (Array.isArray(newResult) && newResult.length > 0) {
          placeTypes.value = newResult;
          nroEvents.value = props.numberOfEvents;

          // Aquí se actualiza localSelectedPlaceTypes si no hay filtros aplicados
          if (!filtersApplied.value) {
            localSelectedPlaceTypes.value = placeTypes.value.map(placeType => placeType.id);
          }
        } else {
          console.log('Sin datos válidos en placeTypesResult');
        }
      }, 
      { immediate: true }
    );

    const allPlaceTypesSelected = computed(() => {
      return placeTypes.value.length > 0 && localSelectedPlaceTypes.value.length === placeTypes.value.length;
    });

    const onAllPlaceTypesChange = (event) => {
      if (event.target.checked) {
        localSelectedPlaceTypes.value = placeTypes.value.map(placeType => placeType.id);
      } else {
        localSelectedPlaceTypes.value = [];
      }
    };

    const onPlaceTypeChange = (placeTypeId, checked) => {
      if (checked) {
        if (!localSelectedPlaceTypes.value.includes(placeTypeId)) {
          localSelectedPlaceTypes.value.push(placeTypeId);
        }
      } else {
        localSelectedPlaceTypes.value = localSelectedPlaceTypes.value.filter(id => id !== placeTypeId);
      }
    };

    const dropdownMenu = ref(null);
    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        // Al abrir, aseguramos que todas las opciones estén seleccionadas si no hay filtros aplicados
        if (!filtersApplied.value) {
          localSelectedPlaceTypes.value = placeTypes.value.map(placeType => placeType.id);
          nextTick(() => {
          if (dropdownMenu.value && dropdown.value) {
            const { top, left, width } = dropdown.value.getBoundingClientRect();
            dropdownMenu.value.style.position = 'absolute';
            dropdownMenu.value.style.top = `${top + window.scrollY + dropdown.value.offsetHeight}px`;
            dropdownMenu.value.style.left = `${left + window.scrollX}px`;
            dropdownMenu.value.style.width = `${width}px`;
          } else {
            console.warn("dropdownMenu or dropdown is null");
          }
        });
      }
     } else {
        // Al cerrar, puedes resetear el ancho si lo has cambiado
        if (dropdownMenu.value) {
          dropdownMenu.value.style.width = ''; // Resetear el ancho al valor por defecto
        }
        }
     
    };

    const handleClickOutside = (event) => {
      if (dropdown.value && !dropdown.value.contains(event.target)) {
        if (isOpen.value) {
          localSelectedPlaceTypes.value = [...props.selectedPlaceTypes]; // Vuelve a los seleccionados originales
        }
        isOpen.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
      if (isOpen.value && !dropdown.value.contains(event.target)) {
          isOpen.value = false;
          dropdown.value.appendChild(dropdownMenu.value); // Devolver al contenedor si está abierto
        }
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    const applyFilter = () => {
      filterState.selectedPlaceType = localSelectedPlaceTypes.value;
      emit('update:selectedPlaceTypes', localSelectedPlaceTypes.value);
      filtersApplied.value = localSelectedPlaceTypes.value.length === 0 || localSelectedPlaceTypes.value.length !== placeTypes.value.length;
      isOpen.value = false; // Cerrar el dropdown después de aplicar el filtro
    };

    const resetFilter = () => {
      localSelectedPlaceTypes.value = placeTypes.value.map(placeType => placeType.id); // Selecciona todos
      filterState.selectedPlaceType = null; 
      emit('update:selectedPlaceTypes', localSelectedPlaceTypes.value); 
      filtersApplied.value = false; 
      isOpen.value = false; 
    };

    return {
      placeTypes,
      nroEvents,
      localSelectedPlaceTypes,
      allPlaceTypesSelected,
      onAllPlaceTypesChange,
      onPlaceTypeChange,
      isOpen,
      toggleDropdown,
      applyFilter,
      resetFilter,
      filtersApplied,
      dropdown,
      dropdownMenu,
    };
  }
};
</script>
