<template>
  <div ref="filterContainer">
    <div class="dropdown" ref="dropdown">
      <button class="dropdown-toggle"
        :class="{ 'selected': filtersApplied }" 
        @click="toggleDropdown">
        {{ $t('eventTypes.title') }}
        <span v-if="!filtersApplied" class="show-filter" :class="{ 'rotated': isOpen }"> <img src="../assets/svg/down.svg" alt="Toggle Filter Icon"></span>
        <span v-if="filtersApplied" @click.stop="resetFilter" class="reset-btn"> <img src="../assets/svg/x.svg" alt="Reset Filter"></span>
      </button>
      <div class="dropdown-menu" :class="{ open: isOpen }" @click.self="isOpen = false" ref="dropdownMenu">
        <!-- Opción "Todos" -->
        <div>
          <input 
            type="checkbox" 
            id="all"
            :checked="allSelected"
            @change="onAllChange"
          />
          <label v-if="nroEvents > 0" for="all">{{ $t('all') }}</label>
        </div>
        <!-- Opciones de tipos de eventos -->
        <div v-if="eventTypes.length > 0">
          <div v-for="type in eventTypes" :key="type.id">
            <input 
              type="checkbox" 
              :id="type.id" 
              :value="type.id" 
              v-model="localSelectedTypes"
              @change="onTypeChange(type.id, $event.target.checked)"
            />
            <label :for="type.id">{{ type.name }} ({{ type.count }})</label>
          </div>
        </div>
        <div v-else>
          <p>{{ $t('eventTypes.noEventTypesFound') }}</p>
        </div>
        <!-- Botón Aplicar -->
        <button @click="applyFilter" class="apply-button">
          {{ $t('apply') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted, onUnmounted, nextTick } from 'vue';
import { filterState } from '../filterState'; 


export default {
  props: {
    selectedTypes: {
      type: Array,
      default: () => []
    },
    eventTypesResult: {
      type: Array,
      default: () => []
    },
    numberOfEvents: {
      type: Number
    }
  },
  setup(props, { emit }) {
    const eventTypes = ref([]);
    const localSelectedTypes = ref([...props.selectedTypes]);
    const originalSelectedTypes = ref([...props.selectedTypes]); 
    const filtersApplied = ref(false);
    const isOpen = ref(false);
    const dropdown = ref(null);
    const nroEvents = ref(props.numberOfEvents); 
 
    watch(
      () => props.eventTypesResult,
      (newResult) => {
        if (Array.isArray(newResult) && newResult.length > 0) {
          eventTypes.value = newResult;
          nroEvents.value = props.numberOfEvents;
          if (!filtersApplied.value) {
            localSelectedTypes.value = eventTypes.value.map(eventType => eventType.id);
          }
        } else {
          console.log('Sin datos válidos en eventTypesResult');
        }
      } ,
      { immediate: true }  
    );
       
    const allSelected = computed(() => {
      return eventTypes.value.length > 0 && localSelectedTypes.value.length === eventTypes.value.length;
    });

    const dropdownMenu = ref(null);

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        // Guardar el estado actual al abrir el dropdown
     //   originalSelectedTypes.value = [...localSelectedTypes.value];
     if (!filtersApplied.value) {
      localSelectedTypes.value = eventTypes.value.map(typeId => typeId.id);
        nextTick(() => {
      if (dropdown.value && dropdownMenu.value) {
        const { top, left, width } = dropdown.value.getBoundingClientRect();
        dropdownMenu.value.style.position = 'absolute';
        dropdownMenu.value.style.top = `${top + window.scrollY + dropdown.value.offsetHeight}px`;
        dropdownMenu.value.style.left = `${left + window.scrollX}px`;
        dropdownMenu.value.style.width = `${width}px`;
      } else {
        console.warn("dropdownMenu or dropdown is still null");
      }
    });
    } 
  }else {
          // Al cerrar, puedes resetear el ancho si lo has cambiado
          if (dropdownMenu.value) {
            dropdownMenu.value.style.width = ''; // Resetear el ancho al valor por defecto
          }
      }
   
    };

    const applyFilter = () => {
      filterState.selectedTypeId = localSelectedTypes.value;
      emit('update:selectedTypes', localSelectedTypes.value);
      filtersApplied.value = localSelectedTypes.value.length === 0 || localSelectedTypes.value.length !== eventTypes.value.length;
      isOpen.value = false; // Cerrar el dropdown después de aplicar el filtro
    };

    const resetFilter = () => {
      localSelectedTypes.value = eventTypes.value.map(typeId => typeId.id); // Aquí seleccionas todos los towns filterState.selectedPostcode = localSelectedPostcodes.value; // Seleccionas 'todos'
      filterState.selectedTypeId = null; // Seleccionas 'todos'
      emit('update:selectedTypes', localSelectedTypes.value); 
      filtersApplied.value = false; 
      isOpen.value = false; 
    };


    const handleClickOutside = (event) => {
      if (dropdown.value && !dropdown.value.contains(event.target)) {
        if (isOpen.value) {
          localSelectedTypes.value = [...originalSelectedTypes.value];
        }
        isOpen.value = false;
      }
    };

    const onTypeChange = (typeId, checked) => {
      if (checked) {
        if (!localSelectedTypes.value.includes(typeId)) {
          localSelectedTypes.value.push(typeId);
        }
      } else {
        localSelectedTypes.value = localSelectedTypes.value.filter(id => id !== typeId);
      }
   
    };

    const onAllChange = (event) => {
      if (event.target.checked) {
        localSelectedTypes.value = eventTypes.value.map(typeId => typeId.id);
      } else {
        localSelectedTypes.value = [];
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

 
    return {
      eventTypes,
      nroEvents,
      localSelectedTypes,
      allSelected,
      onAllChange,
      onTypeChange,
      isOpen,
      toggleDropdown,
      applyFilter,
      resetFilter,
      filtersApplied,
      dropdown,
      dropdownMenu
    };
  }
};
</script>

