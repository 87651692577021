import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue'; // Asegúrate de que esta ruta sea correcta

const routes = [
  {
    path: '/:lang?',
    name: 'Home',
    component: Home,
    // Puedes agregar rutas hijas aquí si es necesario
  },
  // Agrega más rutas aquí si es necesario
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
