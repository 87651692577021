<template>
    
    <div class="sponsored-events">
        <h2 class="section-title">{{ $t('sponsor') }}</h2>
        <div class="sponsor-grid">
      <!-- Contenedor de las cards, con desplazamiento horizontal -->
      <div 
        class="sponsor-cards-wrapper" 
        :style="{ transform: `translateX(-${offset * 22}%)` }">
        <div v-if="randomEvents && randomEvents.length > 0" class="sponsor-grid">
            <div v-for="event in randomEvents" :key="event.id" class="sponsor-card" :style="{ '--event-color': getEventColor(event.type?.id) }" @click="openModal(event)"
                style="cursor: pointer;">
                <img :src="getIconSrc(event.type?.id)" alt="icono evento" class="iconoSponsor" />
                <img :src="event.imageUrl" alt="Imagen del evento" class="sponsor-image" />
                <div class="sponsor-info">
                    <h3 :title="event.name">{{ event.name }}</h3>
                    <p :title="event.place.name">{{ event.place.name }}</p>
                    <img :src="getArrowSrc(event.type?.id)" alt="icono flecha" class="flechaSponsor" />
                </div>
            </div>
        <div>
        </div>
        </div>
        </div>
    </div>
       <!-- Flechas para avanzar y retroceder -->
       <div class="navigation-arrows">
        <button @click="prevEvents">
          <img :src="leftArrowIcon" alt="Flecha izquierda" />
        </button>
        <button @click="nextEvents">
          <img :src="rightArrowIcon" alt="Flecha derecha" />
        </button>
      </div>




        <!-- Modal Component -->
        <EventModal :isVisible="isModalVisible" :event="selectedEvent" @close="closeModal"
                    :style="{ '--event-color': getEventColor(selectedEvent?.type?.id) }" />
</div>
 
</template>

<script>
import { defineComponent, ref, watchEffect, watch, computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { filterState } from '../filterState';

import EventModal from './EventModal.vue';
import leftArrowIcon from '../assets/svg/sLeftArrowGray.svg';
import rightArrowIcon from '../assets/svg/sRigthArrowGray.svg';

import musica from '../assets/svg/evento_a3f8b76f-1830-43a6-bc4a-474b5f55bd61.svg';
import teatro from '../assets/svg/evento_55c1c840-2e9a-40a2-89fb-008d2989de19.svg';
import cine from '../assets/svg/evento_bd226083-2579-496d-a1dd-1aa9a08a6855.svg';
import danza from '../assets/svg/evento_cfb8cd34-9d1a-42ba-bcde-c1d4f992de34.svg';
import bertsolarismo from '../assets/svg/evento_a6329d46-f5ae-4b5e-82ba-facf7ffb6919.svg';
import infantil from '../assets/svg/evento_f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c.svg';
import taller from '../assets/svg/evento_6eb6e65a-3a54-4028-8664-37f693b3fe5f.svg';
import exposicion from '../assets/svg/evento_9554edd0-52b9-44fa-b972-f537cc566508.svg';
import conferencia from '../assets/svg/evento_432671dd-b7e0-4e04-8938-a761f815e89f.svg';
import jornada from '../assets/svg/evento_a72db43b-b4ee-454c-aad5-01a61cbaf361.svg';
import concurso from '../assets/svg/evento_86414ffb-4301-4678-9da3-43c3944edc38.svg';
import feria from '../assets/svg/evento_bc3fab61-c2b2-4a7f-a240-27513aa7b0e3.svg';
import fiesta from '../assets/svg/evento_59143b39-414e-4658-9348-c9463716365d.svg';
import festival from '../assets/svg/evento_ad32f50b-8eb5-453a-a5a7-2ae1264a99cc.svg';
import otras from '../assets/svg/evento_e3450f47-c20a-4409-8116-c25b18239af6.svg';

import fmusica from '../assets/svg/flecha_a3f8b76f-1830-43a6-bc4a-474b5f55bd61.svg';
import fteatro from '../assets/svg/flecha_55c1c840-2e9a-40a2-89fb-008d2989de19.svg';
import fcine from '../assets/svg/flecha_bd226083-2579-496d-a1dd-1aa9a08a6855.svg';
import fdanza from '../assets/svg/flecha_cfb8cd34-9d1a-42ba-bcde-c1d4f992de34.svg';
import fbertsolarismo from '../assets/svg/flecha_a6329d46-f5ae-4b5e-82ba-facf7ffb6919.svg';
import finfantil from '../assets/svg/flecha_f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c.svg';
import ftaller from '../assets/svg/flecha_6eb6e65a-3a54-4028-8664-37f693b3fe5f.svg';
import fexposicion from '../assets/svg/flecha_9554edd0-52b9-44fa-b972-f537cc566508.svg';
import fconferencia from '../assets/svg/flecha_432671dd-b7e0-4e04-8938-a761f815e89f.svg';
import fjornada from '../assets/svg/flecha_a72db43b-b4ee-454c-aad5-01a61cbaf361.svg';
import fconcurso from '../assets/svg/flecha_86414ffb-4301-4678-9da3-43c3944edc38.svg';
import fferia from '../assets/svg/flecha_bc3fab61-c2b2-4a7f-a240-27513aa7b0e3.svg';
import ffiesta from '../assets/svg/flecha_59143b39-414e-4658-9348-c9463716365d.svg';
import ffestival from '../assets/svg/flecha_ad32f50b-8eb5-453a-a5a7-2ae1264a99cc.svg';
import fotras from '../assets/svg/flecha_e3450f47-c20a-4409-8116-c25b18239af6.svg';

export default defineComponent({
    name: 'RandomEvents',
    props: {
        lang: {
            type: String,
            required: true,
        },  
        event: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        EventModal,
    },
    setup(props) {
        const randomEvents = ref([]);

        const isModalVisible = ref(false); 
        const selectedEvent = ref({});

        const openModal = (event) => {
            selectedEvent.value = event;
                isModalVisible.value = true;
        };
        const closeModal = () => {
             isModalVisible.value = false;
        };

        const GET_RANDOM_EVENTS = gql`
        query GetRandomEvents($lang: String!, $count: Int!) {
          randomEvents(lang: $lang, count: $count) {
            id
            name
            start
            end
            imageUrl
            url
            price
            place {
                name
                address
                postcode
                distance
                timetable
                point {
                  lat
                  lon
                }
                type {
                  name
                }
                town {
                  id
                  name
                }
              }
              type {
              id
              name
            }
              langs {
                id
                name
              }
            }
          }
      `;

        const { result, loading, error, refetch } = useQuery(GET_RANDOM_EVENTS, {
            lang: props.lang,
            count: 10,
        });

        const itemsPerPage = 3;
        const offset = ref(0);

        const getIconSrc = (id) => {
            switch (id) {
                case 'a3f8b76f-1830-43a6-bc4a-474b5f55bd61': // música
                    return musica;
                case '55c1c840-2e9a-40a2-89fb-008d2989de19': // teatro
                    return teatro;
                case 'bd226083-2579-496d-a1dd-1aa9a08a6855': // cine
                    return cine;
                case 'cfb8cd34-9d1a-42ba-bcde-c1d4f992de34': // danza
                    return danza;
                case 'a6329d46-f5ae-4b5e-82ba-facf7ffb6919': // bertsolarismo
                    return bertsolarismo;
                case 'f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c': // infantil
                    return infantil;
                case '6eb6e65a-3a54-4028-8664-37f693b3fe5f': // taller
                    return taller;
                case '9554edd0-52b9-44fa-b972-f537cc566508': // exposición
                    return exposicion;
                case '432671dd-b7e0-4e04-8938-a761f815e89f': // conferencia
                    return conferencia;
                case 'a72db43b-b4ee-454c-aad5-01a61cbaf361': // jornada
                    return jornada;
                case '86414ffb-4301-4678-9da3-43c3944edc38': // concurso
                    return concurso;
                case 'bc3fab61-c2b2-4a7f-a240-27513aa7b0e3': // feria
                    return feria;
                case '59143b39-414e-4658-9348-c9463716365d': // fiesta
                    return fiesta;
                case 'ad32f50b-8eb5-453a-a5a7-2ae1264a99cc': // festival
                    return festival;
                case 'e3450f47-c20a-4409-8116-c25b18239af6': // otras
                    return otras;
                default:
                    return otras; // Icono por defecto si no hay coincidencia
            }
        };

        const getArrowSrc = (id) => {
            switch (id) {
                case 'a3f8b76f-1830-43a6-bc4a-474b5f55bd61': // música
                    return fmusica;
                case '55c1c840-2e9a-40a2-89fb-008d2989de19': // teatro
                    return fteatro;
                case 'bd226083-2579-496d-a1dd-1aa9a08a6855': // cine
                    return fcine;
                case 'cfb8cd34-9d1a-42ba-bcde-c1d4f992de34': // danza
                    return fdanza;
                case 'a6329d46-f5ae-4b5e-82ba-facf7ffb6919': // bertsolarismo
                    return fbertsolarismo;
                case 'f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c': // infantil
                    return finfantil;
                case '6eb6e65a-3a54-4028-8664-37f693b3fe5f': // taller
                    return ftaller;
                case '9554edd0-52b9-44fa-b972-f537cc566508': // exposición
                    return fexposicion;
                case '432671dd-b7e0-4e04-8938-a761f815e89f': // conferencia
                    return fconferencia;
                case 'a72db43b-b4ee-454c-aad5-01a61cbaf361': // jornada
                    return fjornada;
                case '86414ffb-4301-4678-9da3-43c3944edc38': // concurso
                    return fconcurso;
                case 'bc3fab61-c2b2-4a7f-a240-27513aa7b0e3': // feria
                    return fferia;
                case '59143b39-414e-4658-9348-c9463716365d': // fiesta
                    return ffiesta;
                case 'ad32f50b-8eb5-453a-a5a7-2ae1264a99cc': // festival
                    return ffestival;
                case 'e3450f47-c20a-4409-8116-c25b18239af6': // otras
                    return fotras;
                default:
                    return fotras; // Icono por defecto si no hay coincidencia
            }
        };

        const eventColors = {
            "a3f8b76f-1830-43a6-bc4a-474b5f55bd61": "#7369BC", //musica
            "55c1c840-2e9a-40a2-89fb-008d2989de19": "#D98950", //teatro
            "bd226083-2579-496d-a1dd-1aa9a08a6855": "#4388CD", //cine
            "cfb8cd34-9d1a-42ba-bcde-c1d4f992de34": "#D46C6E", //danza
            "a6329d46-f5ae-4b5e-82ba-facf7ffb6919": "#3B9363", //bertsolarismo
            "f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c": "#D0983E", //infantil
            "6eb6e65a-3a54-4028-8664-37f693b3fe5f": "#DA6C38", //taller
            "9554edd0-52b9-44fa-b972-f537cc566508": "#41AAC4", //exposición
            "432671dd-b7e0-4e04-8938-a761f815e89f": "#84B036", //conferencia
            "a72db43b-b4ee-454c-aad5-01a61cbaf361": "#778D63", //jornada
            "86414ffb-4301-4678-9da3-43c3944edc38": "#AB53D1", //concurso
            "bc3fab61-c2b2-4a7f-a240-27513aa7b0e3": "#D75858", //feria
            "59143b39-414e-4658-9348-c9463716365d": "#EA5786", //fiesta
            "ad32f50b-8eb5-453a-a5a7-2ae1264a99cc": "#1AAB9D", //festival
            "e3450f47-c20a-4409-8116-c25b18239af6": "#6692AA", //otras
        };
        
        const getEventColor = (eventId) => {
            return eventColors[eventId] || '#302F2F'; // Color por defecto negro
        };

        const openEvent = (url) => {
            if (url) {
                window.open(url, '_blank');  // Abre en una nueva ventana
            }
        };

        const visibleEvents = computed(() => {
            console.log("RAM", randomEvents.value.slice(offset.value * itemsPerPage, (offset.value + 1) * itemsPerPage));
        return randomEvents.value.slice(offset.value * itemsPerPage, (offset.value + 1) * itemsPerPage);
        });

const nextEvents = () => {
  if (offset.value + 1 < Math.ceil(randomEvents.value.length / itemsPerPage)) {
    offset.value++;
  }
};

// Función para retroceder al conjunto anterior de eventos
const prevEvents = () => {
  if (offset.value > 0) {
    offset.value--;
  }
};
        // Actualiza `randomEvents` cuando los datos estén disponibles
        watch(result, (newResult) => {
            if (newResult && newResult.randomEvents) {
                randomEvents.value = newResult.randomEvents;
            }
        });

        // Watch para cambios en el idioma
        watch(
            () => filterState.language,
            async (newLanguage) => {
                await refetch({ lang: newLanguage, count: 10 });
            }
        );

        // Watch para cambios en el contador
        watch(
            () => filterState.nroIteracciones,
            async (newNroIteracciones) => {
                if (newNroIteracciones === 10) {
                    await refetch({ lang: props.lang, count: 10 });
                    filterState.nroIteracciones = 0; // Reiniciar el contador a 0
                }
            }
        );

        watchEffect(() => {
            if (result.value && result.value.randomEvents) {
                randomEvents.value = result.value.randomEvents;
            }
        });


        return {
            randomEvents,
            openEvent,
            loading,
            error,
            getArrowSrc,
            getIconSrc,
            getEventColor,
            isModalVisible,
            selectedEvent,
            openModal,
            closeModal,
            nextEvents,
            prevEvents,
            visibleEvents,
            rightArrowIcon,
            leftArrowIcon,
            offset
        };
    },
});
</script>

<style scoped></style>