<template>
  <div ref="filterContainer" v-show="computedGeoAct">
    <div class="dropdown" ref="dropdown">
      <button class="dropdown-toggle" :class="{ 'selected': filtersApplied }" @click="toggleDropdown">
        {{ $t('distance.title') }}
        <span v-if="!filtersApplied" class="show-filter" :class="{ 'rotated': isOpen }"> <img
            src="../assets/svg/down.svg" alt="Toggle Filter Icon"></span>
        <span v-if="filtersApplied" @click.stop="resetFilter" class="reset-btn"> <img src="../assets/svg/x.svg"
            alt="Reset Filter"></span>
      </button>
      <div class="dropdown-menu" :class="{ open: isOpen }" @click.self="isOpen = false" ref="dropdownMenu">
        <div class="slider-container" @click.stop>
          <vue-slider v-model="selectedDistance" :min="1" :max="150" :step="1" show-tooltip="always" :tooltips="true"
            :format="value => `${value} km`" @change="handleSliderChange" />
        </div>
        <button @click="applyFilter" class="apply-button">
          {{ $t('apply') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from '@vueform/slider';
import '@vueform/slider/themes/default.css';
import { ref, onMounted, onUnmounted, nextTick, computed } from 'vue';
import { filterState } from '../filterState';

export default {
  components: {
    VueSlider
  },
  setup(props, { emit }) {
    const selectedDistance = ref(150);
    const isOpen = ref(false);
    const filtersApplied = ref(false);
    const originalDistance = ref(selectedDistance.value) || null;
    const dropdown = ref(null);
    const filterContainer = ref(null);

    const dropdownMenu = ref(null);
    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        originalDistance.value = selectedDistance.value;
        nextTick(() => {
          if (dropdownMenu.value && dropdown.value) {
            const { top, left, width } = dropdown.value.getBoundingClientRect();
            dropdownMenu.value.style.position = 'absolute';
            dropdownMenu.value.style.top = `${top + window.scrollY + dropdown.value.offsetHeight}px`;
            dropdownMenu.value.style.left = `${left + window.scrollX}px`;
            dropdownMenu.value.style.width = `${width}px`;
          } else {
            console.warn("dropdownMenu or dropdown is null");
          }
        });
      } else {
        // Al cerrar, puedes resetear el ancho si lo has cambiado
        if (dropdownMenu.value) {
          dropdownMenu.value.style.width = ''; // Resetear el ancho al valor por defecto
        }
      }
    };

    const applyFilter = () => {
      filterState.selectedDistance = selectedDistance.value;
      emit('update:selectedDistance', selectedDistance.value);
      filtersApplied.value = true;
      isOpen.value = false;
    };

    const resetFilter = () => {
      selectedDistance.value = 150;
      filterState.selectedDistance = null;
      filtersApplied.value = false;
      emit('update:selectedDistance', null);
      isOpen.value = false;
    };


    const handleSliderChange = (newValue) => {
      console.log(`Nueva distancia seleccionada: ${newValue}`);
    };


    const handleClickOutside = (event) => {
      if (!filterContainer.value.contains(event.target)) {
        if (isOpen.value) {
          selectedDistance.value = originalDistance.value;
        }
        isOpen.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    // Computed para observar los cambios en latitude y longitude
    const computedGeoAct = computed(() => {
      return filterState?.latitude && filterState?.longitude;
    });

    return {
      selectedDistance,
      isOpen,
      filtersApplied,
      filterContainer,
      toggleDropdown,
      applyFilter,
      resetFilter,
      handleSliderChange,
      dropdownMenu,
      dropdown,
      toggleDropdown,
      computedGeoAct
    };
  }
};
</script>
