<template>
  <div ref="filterContainer">
    <div class="dropdown" ref="dropdown">
      <button  class="dropdown-toggle"
        :class="{ 'selected': filtersApplied }" 
        @click="toggleDropdown">
        {{ $t('languages.title') }}
        <span v-if="!filtersApplied" class="show-filter" :class="{ 'rotated': isOpen }"> <img src="../assets/svg/down.svg" alt="Toggle Filter Icon"></span>
        <span v-if="filtersApplied" @click.stop="resetFilter" class="reset-btn"> <img src="../assets/svg/x.svg" alt="Reset Filter"></span>
    </button>
    <div class="dropdown-menu" :class="{ open: isOpen }" @click.self="isOpen = false" ref="dropdownMenu">
      
        <!-- Opción "Todos" -->
        <div>
          <input 
            type="checkbox" 
            id="all-languages"
            :checked="allLanguagesSelected"
            @change="onAllLanguagesChange"
          />
          <label v-if="nroEvents > 0" for="all">{{ $t('all') }}</label>
        </div>
        <!-- Opciones de idiomas -->
        <div v-if="languages.length > 0">
          <div v-for="language in languages" :key="language.id">
            <input 
              type="checkbox" 
              :id="language.id" 
              :value="language.id" 
              v-model="localSelectedLanguages"
              @change="onLanguageChange(language.id, $event.target.checked)"
            />
            <label :for="language.id">{{ language.name }} ({{ language.count }})</label>
          </div>
        </div>
        <div v-else>
          <p>{{ $t('languages.noLanguagesFound') }}</p>
        </div>
        <!-- Botón Aplicar -->
        <button @click="applyFilter" class="apply-button">
          {{ $t('apply') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted, onUnmounted, nextTick } from 'vue';
//import { useQuery } from '@vue/apollo-composable';
//import gql from 'graphql-tag';
import { filterState } from '../filterState'; 

export default {
  props: {
    selectedLanguages: {
      type: Array,
      default: () => []
    },
    languagesResult: {
      type: Array,
      default: () => []
    },
    numberOfEvents: {
      type: Number
    }
  },
  setup(props, { emit }) {
    const languages = ref([]);
    const localSelectedLanguages = ref([...props.selectedLanguages]);
    const originalSelectedLanguages = ref([...props.selectedLanguages]); // Para guardar el estado original
    const filtersApplied = ref(false);
    const isOpen = ref(false);
    const dropdown = ref(null);
    const nroEvents = ref(props.numberOfEvents); 

  watch(
      () => props.languagesResult,
      (newResult) => {
        if (Array.isArray(newResult) && newResult.length > 0) {
          languages.value = newResult;
          nroEvents.value = props.numberOfEvents;
      
          if (!filtersApplied.value) {
            localSelectedLanguages.value = languages.value.map(language => language.id);
          } 
        } else {
          console.log('Sin datos válidos en languagesResult');
        }
      }, 
      { immediate: true }
    );
  

    // Computed para verificar si todos los idiomas están seleccionados
    const allLanguagesSelected = computed(() => {
      return languages.value.length > 0 && localSelectedLanguages.value.length === languages.value.length;
    });

    const onAllLanguagesChange = (event) => {
      if (event.target.checked) {
        localSelectedLanguages.value = languages.value.map(langId => langId.id);
      } else {
        localSelectedLanguages.value = [];
      }
    };

    const onLanguageChange = (langId, checked) => {
      if (checked) {
        if (!localSelectedLanguages.value.includes(langId)) {
          localSelectedLanguages.value.push(langId);
        }
      } else {
        localSelectedLanguages.value = localSelectedLanguages.value.filter(id => id !== langId);
      }
    };
    const dropdownMenu = ref(null);
    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        // Guardar el estado actual al abrir el dropdown
        originalSelectedLanguages.value = [...localSelectedLanguages.value];
        nextTick(() => {
          if (dropdownMenu.value && dropdown.value) {
            const { top, left, width } = dropdown.value.getBoundingClientRect();
            dropdownMenu.value.style.position = 'absolute';
            dropdownMenu.value.style.top = `${top + window.scrollY + dropdown.value.offsetHeight}px`;
            dropdownMenu.value.style.left = `${left + window.scrollX}px`;
            dropdownMenu.value.style.width = `${width}px`;
          } else {
            console.warn("dropdownMenu or dropdown is null");
          }
        });
      } else {
        // Al cerrar, puedes resetear el ancho si lo has cambiado
        if (dropdownMenu.value) {
          dropdownMenu.value.style.width = ''; // Resetear el ancho al valor por defecto
        }
      }
    };


    const handleClickOutside = (event) => {
      if (dropdown.value && !dropdown.value.contains(event.target)) {
        if (isOpen.value) {
          // Restaurar el estado original al hacer clic fuera del dropdown
          localSelectedLanguages.value = [...originalSelectedLanguages.value];
        }
        isOpen.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
      if (isOpen.value && !dropdown.value.contains(event.target)) {
          isOpen.value = false;
          dropdown.value.appendChild(dropdownMenu.value); // Devolver al contenedor si está abierto
        }
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    const applyFilter = () => {
      filterState.selectedLangId = localSelectedLanguages.value;
      emit('update:selectedLanguages', localSelectedLanguages.value);
      filtersApplied.value = localSelectedLanguages.value.length === 0 || localSelectedLanguages.value.length !== languages.value.length;
      isOpen.value = false; // Cerrar el dropdown después de aplicar el filtro
    };

    const resetFilter = () => {
      localSelectedLanguages.value = languages.value.map(langId => langId.id); // Aquí seleccionas todos los towns filterState.selectedPostcode = localSelectedPostcodes.value; // Seleccionas 'todos' 
      filterState.selectedLangId = null; // Seleccionas 'todos'
      emit('update:selectedLangIds', localSelectedLanguages.value); 
      filtersApplied.value = false; 
      isOpen.value = false; 
    };

    return {
      languages,
      nroEvents,
      localSelectedLanguages,
    //  selectedDistance,
      allLanguagesSelected,
      onAllLanguagesChange,
      onLanguageChange,
      isOpen,
      applyFilter,
      resetFilter,
      filtersApplied,
      dropdownMenu,
      dropdown,
      toggleDropdown
    };
  }
};

</script>
