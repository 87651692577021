import { createApp, provide, h } from 'vue';
import { createPinia } from 'pinia';
import { ApolloClients } from '@vue/apollo-composable';
import apolloClient from './apolloClient';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import App from './App.vue';
import i18n from './i18n';
import router from './router'; 
import './assets/styles.css';
import * as Sentry from "@sentry/vue";

const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: apolloClient,
    });
  },
  render: () => h(App),
});

const pinia = createPinia();
const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
const setTheme = () => {
  if (prefersDarkScheme.matches) {
    document.body.setAttribute("data-theme", "dark");
  } else {
    document.body.setAttribute("data-theme", "light");
  }
};

Sentry.init({
  app,
  dsn: "https://a012d8359bb852800b7d9bade5ea8129@o4508239589015552.ingest.de.sentry.io/4508239591243856",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/topagenda\.eus\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


// Establecer el tema al cargar la página
setTheme();
prefersDarkScheme.addEventListener("change", setTheme);

app.use(pinia);
app.use(i18n);
app.use(router); 
app.use(PrimeVue, {
  theme: {
      preset: Aura
  },
  
  locale: {
    today: 'Gaur',
    dateFormat: 'yy/mm/dd',
    firstDayOfWeek: 1,
    monthNames:  ['Urtarrila', 'Otsaila', 'Martxoa', 'Apirila', 'Maiatza', 'Ekaina', 'Uztaila', 'Abuztua', 'Iraila', 'Urria', 'Azaroa', 'Abendua'],
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesMin: ['Ig', 'As', 'At', 'Az', 'Og', 'Or', 'La'],
  }
});


app.mount('#app');