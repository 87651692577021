// src/i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
  es: {
    apply: 'Aplicar',
    all: 'Todos',
    summaryNoEventsFound: 'No se han encontrado eventos, prueba con otra búsqueda.',
    summarySingleEvent: 'evento encontrado en',
    summarySingleTown1: '',
    summarySingleTown2: ' municipio, entre las ',
    summary1: 'eventos encontrados en',
    summary2: 'municipios, entre las ',
    summary3: ' y las',
    summary31: '',
    summary4: ', a ',
    summary5: 'kilómetros.',
    sponsor: 'Eventos patrocinados',
    events: {
        find: 'Busca eventos, organízate y no te pierdas nada.',
        chooseDate: 'Elige una fecha',
        title: 'Eventos',
        name: 'Nombre',
        start: 'Inicio',
        end: 'Fin',
        time: 'Hora',
        place: 'Lugar',
        noEvents: 'No se encontraron eventos.',
        selectTown: 'Municipio',
    },
    provinces: { 
        title: 'Provincias',
        noProvincesFound: 'No se encontraron provincias.',
    },
    languages: {
        title: 'Idiomas',
        noLanguagesFound: 'No se encontraron idiomas.',
    },
    places: {
        title: 'Lugares',
        searchPlaceholder: 'Buscar lugar...',
        noPlacesFound: 'No se encontraron lugares.',
        only: 'Solo',
    },
    placeTypes: {
        title: 'Tipos de Lugar',
        notFound: 'No se encontraron tipos de lugar.'
    },
    postcodes: {
        title: 'Códigos Postales',
        noPostcodes: 'Ez da postalik aurkitu.'
    },
    schedule: {
        title: 'Horario',
    },
    towns: {
        title: 'Municipios',
        noTownsFound: 'No se encontraron municipios.',
    },
    eventTypes: {
        title: 'Tipos de Evento',
        noEventTypesFound: 'No se encontraron tipos de eventos.',
    },
    distance: {
        title: 'Distancia',
    },
    itineraries: {
        itinerary: 'Itinerario',
    },
    modal: {
        inicio: 'Hora de inicio',
        fin: 'Hora finalización',
        timetable: 'Horario',
        precio: 'Precio',
        idioma: 'Idioma',
        direccion: 'Dirección',
        aproximada: 'La hora de finalización es aproximada',
        distancia1: 'A',
        distancia2: 'kilómetros de ti',
        placeType: 'Tipo de lugar',
        HowToGet: 'Cómo llegar',
        moreInfo: 'Más información',
        newPage: 'Este enlace te lleva a una página externa. ¿Quieres continuar?',
    },
    privacy: {
        title: 'Política de Privacidad', 
        text1: 'En Topagenda, valoramos la privacidad de nuestros usuarios. No recopilamos ni almacenamos datos sensibles, y no utilizamos cookies ni tecnologías de rastreo.',
        text2: ' La única información que podemos solicitar es la geolocalización mientras utiliza la aplicación, pero esta información no se almacena ni se comparte. La geolocalización es opcional y mejora la experiencia de usuario, filtrando y mostrando la distancia a los eventos. Puede desactivarse en la configuración del dispositivo',
        text3: 'Si tiene alguna pregunta, contáctenos a través de la sección de Contacto',
    },
    contact: {
        email: 'Tu correo',
        subject: 'Asunto',
        message: 'Mensaje',
        questions: '¿Dudas?',
        suggestions: '¿Sugerencias?',
        organize: '¿Organizas eventos y te gustaría aparecer aquí?',
        contact: '¡Escríbenos!',
        messageInput: 'Escribe tu mensaje',
        subjectInput: 'Escribe el asunto',
        emailInput: 'tu corrreo',
        sendButton: 'Enviar',
    },
    notification: {
        send: 'Mensaje enviado',
        thanks: 'Gracias por tu mensaje.',
        notSend: 'No se ha podido enviar el mensaje, disculpe las molestias.',
        error: 'Error',
    },
    calendar: {
        today: 'Hoy',
        clear: 'Borrar',
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Maio', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    }
  },





  eu: {
    provinces: 'Probintziak',
    noEventTypes: 'Ez da ekitaldirik aurkitu.',
    apply: 'Aplikatu',
    all: 'Guztiak',
    summaryNoEventsFound: 'Ez da ekitaldirik aurkitu, saiatu bilaketa desberdin batekin.',
    summarySingleEvent: 'ekitaldi aurkitu da',
    summarySingleTown1: 'Udalerri ',
    summarySingleTown2: 'ean ',
    summary1: 'ekitaldi aurkitu dira ',
    summary2: 'udalerritan, ',
    summary3: 'etatik',
    summary31: 'etara',
    summary4: ',',
    summary5: 'kilometrotan.',
    sponsor: 'Babestutako ekitaldiak',
    events: {
        find: 'Bilatu ekitaldiak, antolatu zaitez eta ez galdu ezer.',
        chooseDate: 'Eguna aukeratu',
        title: 'Ekitaldiak',
        time: 'Ordua',
        name: 'Izena',
        start: 'Hasiera',
        end: 'Amaiera',
        place: 'Lekua',
        noEvents: 'Ez da ekitaldirik aurkitu.',
        selectTown: 'Udalerria',
    },
    languages: {
        title: 'Hizkuntzak',
        noLanguagesFound: 'Ez da hizkuntzarik aurkitu.'
    },
    places: {
        title: 'Tokiak',
        searchPlaceholder: 'Bilatu tokia...',
        noPlacesFound: 'Ez da tokirik aurkitu.',
        only: 'Bakarrik',
    },
    placeTypes: {
        title: "Toki Motak",
        notFound: "Ez da toki motarik aurkitu."
    },
    postcodes: {
        title: 'Posta Kodeak',
        noPostcodes: 'Ez da postarik aurkitu.'
    },
    provinces: { 
        title: 'Probintziak',
        noProvincesFound: 'Ez da probintziarik aurkitu.',
    },
    schedule: {
        title: 'Ordutegia',
    },
    towns: {
        title: 'Udalerriak',
        noTownsFound: 'Ez da udalerririk aurkitu.',
    },
    eventTypes: {
        title: 'Ekitaldi Motak',
        noEventTypesFound: 'Ekitaldi motarik ez da aurkitu.',
    },
    distance: {
        title: 'Distantzia',
    },
    itineraries: {
        itinerary: 'Ibilbidea'
    },
    modal: {
        inicio: 'Hasiera ordua',
        fin: 'Amaiera ordua',
        timetable: 'Ordutegia',
        precio: 'Prezioa',
        idioma: 'Hizkuntza',
        direccion: 'Helbidea',
        aproximada: 'Amaiera ordua gutxi gorabeherakoa da',
        distancia1: 'Zuretik',
        distancia2: 'kilometrora',
        placeType: 'Toki mota',
        HowToGet: 'Nola heldu',
        moreInfo: 'Informazio gehiago',
        newPage: 'Esteka honek kanpoko orri batera eramango zaitu. Jarraitu nahi duzu?',
    },
    privacy: {
        title: 'Pribatutasun Politika', 
        text1: 'Topagenda-n, gure erabiltzaileen pribatutasuna baloratzen dugu. Ez dugu datu sentikorrik biltzen edo gordetzen, eta ez dugu cookierik edo jarraipen-teknologiarik erabiltzen.',
        text2: 'Eskatu dezakegun informazio bakarra geolokalizazioa da aplikazioa erabiltzen duzun bitartean, baina informazio hori ez da gordetzen edo partekatzen. Geolokalizazioa aukerakoa da eta erabiltzaileen esperientzia hobetzen du, ekitaldietarako distantzia hautatuz eta erakutsiz. Gailuaren konfigurazioan desaktiba daiteke.',
        text3: 'Galderarik izanez gero, jarri gurekin harremanetan Kontaktua atalaren bidez.',
    },
    contact: {
        email: 'Zure posta',
        subject: 'Gaia',
        message: 'Mezua',
        questions: 'Zalantzarik?',
        suggestions: 'Iradokizunik?',
        organize: 'Ekitaldiak antolatzen dituzu eta hemen agertu nahi al zenuke?',
        contact: 'Idatzi iezaguzu!',
        messageInput: 'Zure mezua idatzi',
        subjectInput: 'Gaia idatzi',
        emailInput: 'Zure posta',
        sendButton: 'Bidali',
    },
    notification: {
        send: 'Mezua bidali da',
        thanks: 'Eskerrik asko zure mezuagatik.',
        notSend: 'Mezua ezin izan da bidali, barkatu eragozpenak.',
        error: 'Errorea',
    },
    calendar: {
        today: 'Gaur',
        clear: 'Ezabatu',
        dayNamesMin: ['As', 'At', 'Az', 'Og', 'Or', 'La', 'Ig'],
        monthNames: ['Urtarrila', 'Otsaila', 'Martxoa', 'Apirila', 'Maiatza', 'Ekaina', 'Uztaila', 'Abuztua', 'Iraila', 'Urria', 'Azaroa', 'Abendua'],
    }
  },





  en: {
    provinces: 'Provinces',
    noEventTypes: 'No event types found.',
    apply: 'Apply',
    all: 'All',
    summaryNoEventsFound: 'No events found, try a different search.',
    summarySingleEvent: 'event found in',
    summarySingleTown1: '',
    summarySingleTown2: ' municipality, between',
    summary1: 'events found in',
    summary2: 'municipalities, between',
    summary3: ' and',
    summary31: '',
    summary4: ', at',
    summary5: 'kilometers.',
    sponsor: 'Sponsored events',
    events: {
        find: `Search for events, get organized, and don't miss anything.`,
        chooseDate: 'Choose a date',
        title: 'Events',
        time: 'Time',
        name: 'Name',
        start: 'Start',
        end: 'End',
        place: 'Place',
        noEvents: 'No events found.',
        selectTown: 'Town',
    },
    languages: {
        title: 'Languages',
        noLanguagesFound: 'No languages found.',
    },
    places: {
        title: 'Places',
        searchPlaceholder: 'Search place...',
        noPlacesFound: 'No places found.',
        only: 'Only',
    },
    placeTypes: {
        title: "Place Types",
        notFound: "No place types found."
    },
    postcodes: {
        title: 'Postcodes',
        noPostcodes: 'No postcodes found.'
    },
    provinces: { 
        title: 'Provinces',
        noProvincesFound: 'No provinces found.',
    },
    schedule: {
        title: 'Schedule',
    },
    towns: {
        title: 'Towns',
        noTownsFound: 'No towns found.',
    },
    eventTypes: {
        title: 'Event Types',
        noEventTypesFound: 'No event types found.',
    },
    distance: {
        title: 'Distance',
    },
    itineraries: {
        itinerary: 'Itinerary',
    },
    modal: {
        inicio: 'Start time',
        fin: 'End time',
        timetable: 'Timetable',
        precio: 'Price',
        idioma: 'Language',
        direccion: 'Address',
        aproximada: 'The end time is approximate',
        distancia1: '',
        distancia2: 'kilometers away from you',
        placeType: 'Place type',
        HowToGet: 'How to get there',
        moreInfo: 'More information',
        newPage: 'This link takes you to an external page. Do you want to continue?',
    },
    privacy: {
        title: 'Privacy Policy', 
        text1: 'At Topagenda, we value the privacy of our users. We do not collect or store sensitive data, and we do not use cookies or tracking technologies.',
        text2: 'The only information we may request is geolocation while using the app, but this information is not stored or shared. Geolocation is optional and improves the user experience, filtering and showing the distance to the events. It can be disabled in the device settings.',
        text3: 'If you have any questions, please contact us through the Contact section.',
    },
    contact: {
        email: 'Your email',
        subject: 'Subject',
        message: 'Message',
        questions: 'Questions?',
        suggestions: 'Suggestions?',
        organize: 'Do you organize events and would like to appear here?',
        contact: 'Contact us!',
        messageInput: 'Write your message',
        subjectInput: 'Write the subject',
        emailInput: 'your email',
        sendButton: 'Send',
    },
    notification: {
        send: 'Message sent',
        thanks: 'Thank you for your message.',
        notSend: 'The message could not be sent, sorry for the inconvenience.',
        error: 'Error',
    },
    calendar: {
        today: 'Today',
        clear: 'Clear',
        dayNamesMin: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        monthNames: ['January', 'February', 'March', 'April', 'Mai', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    }
  }
};
const i18n = createI18n({
    locale: 'eu', // Idioma por defecto
    messages,
  });
  
/*


// Función para establecer el idioma
const setLocale = (language) => {
  switch (language) {
    case 'es':
      locale.current = {
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        today: 'Hoy',
        firstDayOfWeek: 1, // Semana comienza el lunes
        dateFormat: 'dd/mm/yy', // Formato de fecha
      };
      break;
    case 'en':
      locale.current = {
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        today: 'Today',
        firstDayOfWeek: 0, // Semana comienza el domingo
        dateFormat: 'mm/dd/yy', // Formato de fecha
      };
      break;
    case 'eu':
      locale.current = {
        dayNames: ['Igandea', 'Astelehena', 'Asteartea', 'Astezkena', 'Osteguna', 'Ostirala', 'Larunbata'],
        dayNamesShort: ['Ig', 'Al', 'Ar', 'Az', 'Og', 'Ol', 'Lr'],
        dayNamesMin: ['I', 'A', 'A', 'O', 'O', 'L', 'L'],
        monthNames: ['Urtarrila', 'Otsaila', 'Martxoa', 'Apirila', 'Maiatza', 'Ekaina', 'Uztaila', 'Abuztua', 'Iraila', 'Urria', 'Azaroa', 'Abendua'],
        monthNamesShort: ['Urt', 'Ots', 'Mar', 'Api', 'Mai', 'Eka', 'Uzt', 'Abu', 'Ira', 'Urr', 'Aza', 'Abd'],
        today: 'Gaur',
        firstDayOfWeek: 1, // Semana comienza el lunes
        dateFormat: 'dd/mm/yy', // Formato de fecha
      };
      break;
    default:
      break;
  }
};



setLocale('es');
*/
export default i18n;
