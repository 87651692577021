<!-- PostcodeFilter.vue -->
<template>
  <div ref="filterContainer">
    <div class="dropdown" ref="dropdown">
      <button class="dropdown-toggle" 
      :class="{ 'selected': filtersApplied }" 
      @click="toggleDropdown">
      {{ $t('postcodes.title') }}
      <span v-if="!filtersApplied" class="show-filter" :class="{ 'rotated': isOpen }"> <img src="../assets/svg/down.svg" alt="Toggle Filter Icon"></span>
      <span v-if="filtersApplied" @click.stop="resetFilter" class="reset-btn"> <img src="../assets/svg/x.svg" alt="Reset Filter"></span>
     </button>
     <div class="dropdown-menu" :class="{ open: isOpen }" @click.self="isOpen = false" ref="dropdownMenu">
        <div>
          <input 
            type="checkbox" 
            id="all-postcodes"
            :checked="allPostcodesSelected"
            @change="onAllPostcodesChange"
          />
          <label v-if="nroEvents > 0" for="all">{{ $t('all') }}</label>
        </div>
        <!-- Opciones de códigos postales -->
        <div v-if="postcodes.length > 0">
          <div v-for="postcode in postcodes" :key="postcode.id">
            <input 
              type="checkbox" 
              :id="postcode.id" 
              :value="postcode.id" 
              v-model="localSelectedPostcodes"
              @change="onPostcodeChange(postcode.id, $event.target.checked)"
            />
            <label :for="postcode.id">{{ postcode.name }} ({{ postcode.count }})</label>
          </div>
        </div>
        <div v-else>
          <p>{{ $t('postcodes.noPostcodes') }}</p>
        </div>
        <!-- Botón de aplicar -->
        <button @click="applyFilter" class="apply-button">
          {{ $t('apply') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted, onUnmounted, nextTick  } from 'vue';
import { filterState } from '../filterState'; 

export default {
  props: {
    selectedPostcodes: {
      type: Array,
      default: () => []
    },
    postcodesResult: {
      type: Array,
      default: () => []
    },
    numberOfEvents: {
      type: Number
    }
  },
  setup(props, { emit }) {
    const postcodes = ref([]);
    const localSelectedPostcodes = ref([...props.selectedPostcodes]);
    const originalSelectedPostcodes = ref([...props.selectedPostcodes]); 
    const filtersApplied = ref(false);
    const isOpen = ref(false);
    const dropdown = ref(null);
    const nroEvents = ref(props.numberOfEvents); 
 
    watch(
      () => props.postcodesResult,
      (newResult) => {
        if (Array.isArray(newResult) && newResult.length > 0) {
          // Obtener los nuevos postcodes
          postcodes.value = newResult;
          nroEvents.value = props.numberOfEvents;  
        //  const newPostcodes = postcodesResult.value.panels.counts.postcodes.map(postcode => postcode.id);

          // Si el filtro estaba aplicado, mantener las selecciones previas que todavía son válidas
          if (!filtersApplied.value) {
            localSelectedPostcodes.value = postcodes.value.filter(postcode => postcode.id);
         //  emit('update:selectedPostcodes', localSelectedPostcodes.value);
        }
        } else {
          console.log('Sin datos válidos en townsResult');
        }
      } ,
      { immediate: true }  
    );

    // Computado para verificar si todos los códigos postales están seleccionados
    const allPostcodesSelected = computed(() => {
      return postcodes.value.length > 0 && localSelectedPostcodes.value.length === postcodes.value.length;
    });

    const dropdownMenu = ref(null);
    // Función para alternar la visibilidad del dropdown
    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;   
      if (isOpen.value) {
         if (!filtersApplied.value) {
        localSelectedPostcodes.value = postcodes.value.map(postcodes => postcodes.id);
        nextTick(() => {
          if (dropdownMenu.value && dropdown.value) {
            const { top, left, width } = dropdown.value.getBoundingClientRect();
            dropdownMenu.value.style.position = 'absolute';
            dropdownMenu.value.style.top = `${top + window.scrollY + dropdown.value.offsetHeight}px`;
            dropdownMenu.value.style.left = `${left + window.scrollX}px`;
            dropdownMenu.value.style.width = `${width}px`;
          } else {
            console.warn("dropdownMenu or dropdown is null");
          }
        });  
      } 
    } else {
        // Al cerrar, puedes resetear el ancho si lo has cambiado
        if (dropdownMenu.value) {
          dropdownMenu.value.style.width = ''; // Resetear el ancho al valor por defecto
        }
      }
     
   
    };

    // Función para aplicar filtros y cerrar el dropdown
    const applyFilter = () => {
      filterState.selectedPostcode = localSelectedPostcodes.value;
      emit('update:selectedPostcodes', localSelectedPostcodes.value);
      filtersApplied.value = localSelectedPostcodes.value.length === 0 || localSelectedPostcodes.value.length !== postcodes.value.length;
      isOpen.value = false;
    };

    const resetFilter = () => {
      localSelectedPostcodes.value = postcodes.value.map(postcodes => postcodes.id);
      filterState.selectedPostcode = null;
      emit('update:selectedPostcodes', localSelectedPostcodes.value); 
      filtersApplied.value = false; 
      isOpen.value = false; 
    };

    // Función para manejar clics fuera del filtro
    const handleClickOutside = (event) => {
      if (dropdown.value && !dropdown.value.contains(event.target)) {
        if (isOpen.value) {
          // Restaurar el estado original al hacer clic fuera del dropdown
          localSelectedPostcodes.value = [...originalSelectedPostcodes.value];
        }
        isOpen.value = false;
      }
    };

    // Función para manejar la selección de códigos postales
    const onPostcodeChange = (postcodeId, checked) => {
      if (checked) {
        if (!localSelectedPostcodes.value.includes(postcodeId)) {
          localSelectedPostcodes.value.push(postcodeId);
        }
      } else {
        localSelectedPostcodes.value = localSelectedPostcodes.value.filter(id => id !== postcodeId);
      }
    };

    // Manejar el cambio en la opción "Todos"
    const onAllPostcodesChange = (event) => {
      if (event.target.checked) {
        localSelectedPostcodes.value = postcodes.value.map(postcodes => postcodes.id);
      } else {
        localSelectedPostcodes.value = [];
      }
    };


    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      postcodes,
      nroEvents,
      selectedPostcode: ref(null), 
      localSelectedPostcodes,
      allPostcodesSelected,
      onAllPostcodesChange,
      onPostcodeChange,
      isOpen,
      toggleDropdown,
      applyFilter,
      filtersApplied,
      resetFilter,
      dropdown,
      dropdownMenu,
    };
  }
};
</script>
