<template>
  <div ref="filterContainer">
    <div class="dropdown" ref="dropdown">
      <button 
        class="dropdown-toggle" 
        :class="{ 'selected': filtersApplied }" 
        @click="toggleDropdown"
      >
      {{ $t('provinces.title') }}
      <span v-if="!filtersApplied" class="show-filter" :class="{ 'rotated': isOpen }"> <img src="../assets/svg/down.svg" alt="Toggle Filter Icon"></span>
      <span v-if="filtersApplied" @click.stop="resetFilter" class="reset-btn"> <img src="../assets/svg/x.svg" alt="Reset Filter"></span>
   </button>
   <div class="dropdown-menu" :class="{ open: isOpen }" @click.self="isOpen = false" ref="dropdownMenu">
        <div>
          <input 
            type="checkbox"
            id="all-provinces"
            :checked="allSelected"
            @change="onAllChange"
          />
          <label v-if="nroEvents > 0" for="all">{{ $t('all') }}</label>
        </div>    

        <div v-if="provinces.length > 0">
          <div v-for="province in provinces" :key="province.id">
            <input 
              type="checkbox" 
              :id="province.id" 
              :value="province.id" 
              v-model="localSelectedProvinces"
              @change="onProvinceChange(province.id, $event.target.checked)"
            />
            <label :for="province.id">{{ province.name }} ({{ province.count }})</label>
          </div>
        </div>
        <div v-else>
          <p>{{ $t('provinces.noProvincesFound') }}</p>
        </div>

        <button @click="applyFilter" class="apply-button">
          {{ $t('apply') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, computed, nextTick } from 'vue';
import { filterState } from '../filterState'; 

export default {
  props: {
    selectedProvinces: {
      type: Array,
      default: () => []
    },
    provincesResult: {
      type: Array,
      default: () => []
    },
    numberOfEvents: {
      type: Number
    }
  },
  setup(props, { emit }) {
    const provinces = ref([]);
    const localSelectedProvinces = ref([...props.selectedProvinces]);
    const originalSelectedProvinces = ref([...props.selectedProvinces]); 
    const filtersApplied = ref(false);
    const isOpen = ref(false);
    const dropdown = ref(null);
    const nroEvents = ref(props.numberOfEvents); 
 
    watch(
      () => props.provincesResult,
      (newResult) => {
        if (Array.isArray(newResult) && newResult.length > 0) {
          provinces.value = newResult;
          nroEvents.value = props.numberOfEvents;
        
        if (!filtersApplied.value) {
          localSelectedProvinces.value = provinces.value.map(province => province.id);
        }
        } else {
          console.log('Sin datos válidos en provincesResult');
        }
      }, 
      { immediate: true }
    );
       

    const dropdownMenu = ref(null);
    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        // Guardar el estado actual al abrir el dropdown
        originalSelectedProvinces.value = [...localSelectedProvinces.value];
        nextTick(() => {
          if (dropdownMenu.value && dropdown.value) {
            const { top, left, width } = dropdown.value.getBoundingClientRect();
            dropdownMenu.value.style.position = 'absolute';
            dropdownMenu.value.style.top = `${top + window.scrollY + dropdown.value.offsetHeight}px`;
            dropdownMenu.value.style.left = `${left + window.scrollX}px`;
            dropdownMenu.value.style.width = `${width}px`;
          } else {
            console.warn("dropdownMenu or dropdown is null");
          }
        });
      } else {
        // Al cerrar, puedes resetear el ancho si lo has cambiado
        if (dropdownMenu.value) {
          dropdownMenu.value.style.width = ''; // Resetear el ancho al valor por defecto
        }
      }
    };

    const applyFilter = () => {
      filterState.selectedProvince = localSelectedProvinces.value;
      emit('update:selectedProvinces', localSelectedProvinces.value);
      filtersApplied.value = localSelectedProvinces.value.length === 0 || localSelectedProvinces.value.length !== provinces.value.length;
      isOpen.value = false;
    };

    const resetFilter = () => {
      localSelectedProvinces.value = provinces.value.map(province => province.id); 
      filterState.selectedProvince = null; 
      emit('update:selectedProvinces', localSelectedProvinces.value); 
      filtersApplied.value = false; 
      isOpen.value = false; 
    };

    const handleClickOutside = (event) => {
      if (dropdown.value && !dropdown.value.contains(event.target)) {
        if (isOpen.value) {
          // Restaurar el estado original al hacer clic fuera del dropdown
          localSelectedProvinces.value = [...originalSelectedProvinces.value];
        }
        isOpen.value = false;
      }
    };

    const onProvinceChange = (provinceId, checked) => {
      if (checked) {
        if (!localSelectedProvinces.value.includes(provinceId)) {
          localSelectedProvinces.value.push(provinceId);
        }
      } else {
        localSelectedProvinces.value = localSelectedProvinces.value.filter(id => id !== provinceId);
      }
    };

    const onAllChange = (event) => {
      if (event.target.checked) {
        localSelectedProvinces.value = provinces.value.map(province => province.id);
      } else {
        localSelectedProvinces.value = [];
      }
    };

    const allSelected = computed(() => {
      return provinces.value.length > 0 && localSelectedProvinces.value.length === provinces.value.length;
    });

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      provinces,
      nroEvents,
      selectedProvince: ref(null), 
      localSelectedProvinces,
   //   selectedDistance,
      allSelected,
      isOpen,
      toggleDropdown,
      dropdown,
      applyFilter,
      resetFilter,
      filtersApplied,
      onProvinceChange,
      onAllChange,
      dropdownMenu,
    };
  }
};
</script>
