<template>
  <div id="map" style="height: 400px; width: 100%;"></div>
</template>

<script>
import { onMounted } from 'vue';
import L from 'leaflet';

export default {
  props: {
    lat: {
      type: Number,
      required: true,
    },
    lon: {
      type: Number,
      required: true,
    },
    eventName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      // Inicializar el mapa
      const map = L.map('map').setView([props.lat, props.lon], 15);

      // Añadir el tile layer de OpenStreetMap
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
      }).addTo(map);

      // Añadir un marcador para el evento
      L.marker([props.lat, props.lon])
        .addTo(map)
        .bindPopup(props.eventName)
        .openPopup();
    });
  },
};
</script>

<style>
#map {
  height: 400px;
  width: 100%;
}
</style>