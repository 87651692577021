import { reactive } from 'vue';

export const filterState = reactive({
  selectedProvince: null,
  selectedPostcode: null,
  selectedTown: null,
  selectedPlace: null,
  selectedPlaceType: null,
  selectedLangId: null,
  selectedTypeId: null,
  selectedStartTime: null,
  selectedEndTime: null,
  selectedDistance: null,
  latitude: null,
  longitude: null,
  language: null,
  painted: false,
  nroIteracciones: 0,
});
