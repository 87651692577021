<!-- PlaceFilter.vue -->
<template>
   <div ref="filterContainer">
    <div class="dropdown" ref="dropdown">
      <button class="dropdown-toggle"
      :class="{ 'selected': filtersApplied }" 
      @click="toggleDropdown">
      {{ $t('places.title') }}
      <span v-if="!filtersApplied" class="show-filter" :class="{ 'rotated': isOpen }"> <img src="../assets/svg/down.svg" alt="Toggle Filter Icon"></span>
      <span v-if="filtersApplied" @click.stop="resetFilter" class="reset-btn"> <img src="../assets/svg/x.svg" alt="Reset Filter"></span>
    </button>
    <div class="dropdown-menu" :class="{ open: isOpen }" @click.self="isOpen = false" ref="dropdownMenu">
        <!-- Campo de búsqueda -->
        <div class="place-input">
          <input
            id="place_id"
            type="text"
            v-model="searchQuery"
            :placeholder= "$t('places.searchPlaceholder')"
            class="search-input"
          />
        </div>
        
        <!-- Opción "Todos" -->
        <div>
          <input 
            type="checkbox" 
            id="all-places"
            :checked="allPlacesSelected"
            @change="onAllPlacesChange"
          />
          <label v-if="nroEvents > 0" for="all">{{ $t('all') }}</label>
        </div>
        
        <!-- Opciones de lugares filtradas -->
        <div v-if="filteredPlaces.length > 0">
          <div v-for="place in filteredPlaces" :key="place.id" class="place-option">
            <input 
              type="checkbox" 
              :id="place.id" 
              :value="place.id" 
              v-model="localSelectedPlaces"
              @change="onPlaceChange(place.id, $event.target.checked)"
            />
            <label :for="place.id">{{ place.name }} ({{ place.count }})</label>
            <!-- Botón para seleccionar solo este lugar -->
            <button @click="selectOnly(place.id)" class="solo-button">
              {{ $t('places.only') }}
            </button>
          </div>
        </div>
        <div v-else>
          <p>{{ $t('places.noPlacesFound') }}</p>
        </div>
        <button @click="applyFilter" class="apply-button">
          {{ $t('apply') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted, onUnmounted, nextTick } from 'vue';
import { filterState } from '../filterState'; 

export default {
  props: {
    selectedPlaces: {
      type: Array,
      default: () => []
    },
    placesResult: {
      type: Array,
      default: () => []
    },
    numberOfEvents: {
      type: Number
    }
  },
  setup(props, { emit }) {
    const places = ref([]);
    const localSelectedPlaces = ref([...props.selectedPlaces]);
    const originalSelectedPlaces = ref([...props.selectedPlaces]);
    const filtersApplied = ref(false);
    const isOpen = ref(false);
    const searchQuery = ref('');
    const dropdown = ref(null);
    const nroEvents = ref(props.numberOfEvents); 


    watch(
      () => props.placesResult,
      (newResult) => {
        if (Array.isArray(newResult) && newResult.length > 0) {
          places.value = newResult;
          nroEvents.value = props.numberOfEvents;
          // Si el filtro estaba aplicado, mantener las selecciones previas que todavía son válidas
          if (!filtersApplied.value) {
            localSelectedPlaces.value =  places.value.filter(place => place.id);
           }
        } else {
          console.log('Sin datos válidos en placesResult');
        }
      } ,
      { immediate: true }  
    );


    // Computado para filtrar los lugares según el término de búsqueda
    const filteredPlaces = computed(() => {
      const query = searchQuery.value.toLowerCase();
      return places.value.filter(place =>
        place.name.toLowerCase().includes(query)
      );
    });

    // Computado para verificar si todos los lugares están seleccionados
    const allPlacesSelected = computed(() => {
      return places.value.length > 0 && localSelectedPlaces.value.length === places.value.length;
    });

    // Manejar cambio en la opción "Todos"
    const onAllPlacesChange = (event) => {
      if (event.target.checked) {
        localSelectedPlaces.value = places.value.map(place => place.id);
      } else {
        localSelectedPlaces.value = [];
      }
    };

    // Manejar cambios en los lugares individuales
    const onPlaceChange = (placeId, checked) => {
      if (checked) {
        if (!localSelectedPlaces.value.includes(placeId)) {
          localSelectedPlaces.value.push(placeId);
        }
      } else {
        localSelectedPlaces.value = localSelectedPlaces.value.filter(id => id !== placeId);
      }
    };

    // Seleccionar solo un lugar
    const selectOnly = (placeId) => {
      localSelectedPlaces.value = [placeId];
    };

    const dropdownMenu = ref(null);
    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
       // originalSelectedPlaces.value = [...localSelectedPlaces.value];
        if (!filtersApplied.value) {
          localSelectedPlaces.value = places.value.map(place => place.id);
        }

      nextTick(() => {
      if (dropdownMenu.value && dropdown.value) {
        const { top, left, width } = dropdown.value.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        
        // Posición inicial del desplegable debajo del filtro
        let dropdownLeft = left + window.scrollX;
        
        // Si el desplegable se sale del borde derecho de la ventana, ajusta hacia la izquierda
        if (dropdownLeft + width > windowWidth) {
          dropdownMenu.value.style.left = `${dropdownLeft - width}px`;
        }else {
          dropdownMenu.value.style.left = `${dropdownLeft}px`;
        }

        dropdownMenu.value.style.position = 'absolute';
        dropdownMenu.value.style.top = `${top + window.scrollY + dropdown.value.offsetHeight}px`;
        dropdownMenu.value.style.width = `${width}px`;
       
      } else {
        console.warn("dropdownMenu or dropdown is null");
      }
    });
      } else {
        // Al cerrar, puedes resetear el ancho si lo has cambiado
        if (dropdownMenu.value) {
          dropdownMenu.value.style.width = ''; // Resetear el ancho al valor por defecto
        }
      }
    };

    const handleClickOutside = (event) => {
      if (dropdown.value && !dropdown.value.contains(event.target)) {
        if (isOpen.value) {
          localSelectedPlaces.value = [...originalSelectedPlaces.value];
        }
        isOpen.value = false;
      }
    };

    onMounted(() => {
      if (!dropdown.value || !dropdownMenu.value) {
        console.warn("dropdownMenu or dropdown is null");
      }
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    const applyFilter = () => {
      filterState.selectedPlace = localSelectedPlaces.value;
      emit('update:selectedPlaces', localSelectedPlaces.value);
      filtersApplied.value = localSelectedPlaces.value.length === 0 || localSelectedPlaces.value.length !== places.value.length;
      isOpen.value = false;
    };

    const resetFilter = () => {
      localSelectedPlaces.value = places.value.map(places => places.id); // Aquí seleccionas todos los postcodes
      filterState.selectedPlace = null; // Seleccionas 'todos' 
    
      emit('update:selectedPlaces', localSelectedPlaces.value); 
      filtersApplied.value = false; 
      isOpen.value = false; 
    };

    return {
      places,
      nroEvents,
      selectedPlace: ref(null), 
      localSelectedPlaces,
  //    selectedDistance,
      filteredPlaces,
      searchQuery, 
      allPlacesSelected,
      onAllPlacesChange,
      onPlaceChange,
      isOpen,
      toggleDropdown,
      applyFilter,
      resetFilter,
      filtersApplied,
      selectOnly,
      dropdown,
      dropdownMenu,
    };
  }
};
</script>
