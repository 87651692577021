<template>
  <div ref="filterContainer">
    <div class="dropdown" ref="dropdown">
      <button class="dropdown-toggle" :class="{ 'selected': filtersApplied }" @click="toggleDropdown">
        {{ $t('schedule.title') }}
        <span v-if="!filtersApplied" class="show-filter" :class="{ 'rotated': isOpen }"> <img
            src="../assets/svg/down.svg" alt="Toggle Filter Icon"></span>
        <span v-if="filtersApplied" @click.stop="resetFilter" class="reset-btn"> <img src="../assets/svg/x.svg"
            alt="Reset Filter"></span>
      </button>
      <div class="dropdown-menu" :class="{ open: isOpen }" @click.self="isOpen = false" ref="dropdownMenu">
        <div class="slider-container" @click.stop>
          <Slider v-model="timeRange" :min="480" :max="1560" :step="30" show-tooltip="always" :tooltips="true"
            :merge="1440" :default="[600, 1000]" :format="value => {
              const totalMinutes = value; // Obtener el valor total en minutos
              const hours = Math.floor(totalMinutes / 60); // Calcular las horas
              const minutes = totalMinutes % 60; // Calcular los minutos

              // Ajustar las horas para que se mantenga en formato de 24 horas
              const adjustedHours = hours % 24; // Esto hará que 24 se convierta en 0, 25 en 1, etc.
              return `${adjustedHours}:${minutes < 10 ? '0' + minutes : minutes}`;
            }" @change="handleSliderChange" />

        </div>
        <button @click="applyFilter" class="apply-button">
          {{ $t('apply') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from '@vueform/slider';
import '@vueform/slider/themes/default.css';
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import { filterState } from '../filterState';

export default {
  components: {
    Slider
  },
  setup(props, { emit }) {
    const timeRange = ref([480, 1560]); // 0 minutos (00:00) a 1440 minutos (23:59)
    const isOpen = ref(false);
    const filtersApplied = ref(false);
    const showTimeInfo = ref(false); // Para controlar la visibilidad de la información de tiempo
    const originalTimeRange = ref([...timeRange.value]); // Guardar el rango original
    const filterContainer = ref(null);

    const dropdown = ref(null);
    const dropdownMenu = ref(null);
    // Restaura el estado original al abrir el dropdown
    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        // showTimeInfo.value = false; // Reiniciar la visualización de la información al abrir
        // Guardar el rango actual al abrir el dropdown
        originalTimeRange.value = [...timeRange.value];
        nextTick(() => {
          if (dropdownMenu.value && dropdown.value) {
            const { top, left, width } = dropdown.value.getBoundingClientRect();
            dropdownMenu.value.style.position = 'absolute';
            dropdownMenu.value.style.top = `${top + window.scrollY + dropdown.value.offsetHeight}px`;
            dropdownMenu.value.style.left = `${left + window.scrollX}px`;
            dropdownMenu.value.style.width = `${width}px`;

          } else {
            console.warn("dropdownMenu or dropdown is null");
          }
        });
      } else {
        // Al cerrar, puedes resetear el ancho si lo has cambiado
        if (dropdownMenu.value) {
          dropdownMenu.value.style.width = ''; // Resetear el ancho al valor por defecto
        }
      }
    };

    const applyFilter = () => {
      const startTime = convertMinutesToTime(timeRange.value[0]); // Guardar el primer valor como startTime
      const endTime = convertMinutesToTime(timeRange.value[1]);   // Guardar el rango de tiempo en el estado del filtro

      filterState.selectedStartTime = startTime;
      filterState.selectedEndTime = endTime;

      emit('update:selectedTimes', timeRange.value);
      filtersApplied.value = true; // Se aplicaron filtros
      isOpen.value = false; // Cerrar el dropdown después de aplicar el filtro
    };

    const convertMinutesToTime = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;

      // Ajustar las horas para que se muestren correctamente
      const adjustedHours = hours >= 24 ? hours - 24 : hours;

      return `${String(adjustedHours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
    };

    const resetFilter = () => {
      timeRange.value = [480, 1560];
      filterState.selectedStartTime = null; // Guardar el primer valor como startTime
      filterState.selectedEndTime = null;
      filtersApplied.value = false;
      emit('update:selectedTimes', [480, 1560]); // Emitir la actualización al padre
      isOpen.value = false; // Cerrar el dropdown
    };

    // Manejo de clics fuera del dropdown
    const handleClickOutside = (event) => {
      if (!filterContainer.value.contains(event.target)) {
        if (isOpen.value) {
          timeRange.value = [...originalTimeRange.value];
        }
        isOpen.value = false;
      }
    };

    const onTimeChange = (newTimeRange) => {
      timeRange.value = newTimeRange; // Actualiza el rango de tiempo seleccionado
      emit('update:selectedTimes', timeRange.value); // Emite el evento al componente padre
    };


    const handleSliderChange = async (value) => {
      // Destructura los valores actuales
      let [start, end] = value;

      // Comprueba si el rango es menor a 30 minutos
      let distance = end - start;

      if (distance < 30) {
        // Ajusta el valor de 'end' para que esté a 30 minutos de 'start'
        end = start + 30;

        // Si 'end' supera el máximo permitido, ajusta también 'start'
        if (end > 1560) {
          end = 1560;
          start = end - 30; // Asegúrate de que start no sea menor que el mínimo
        }
      }

      // Actualiza el rango del slider
      timeRange.value = [start, end];

      // Forzar la actualización visual del slider
      await nextTick();

      // Muestra la información de tiempo
      showTimeInfo.value = true;
    };


    // Escuchar clics fuera del dropdown
    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      timeRange,
      isOpen,
      filtersApplied,
      showTimeInfo,
      filterContainer,
      toggleDropdown,
      handleSliderChange,
      applyFilter,
      resetFilter,
      onTimeChange,
      dropdownMenu,
      dropdown,
    };
  }
};
</script>
