<template>
  <nav class="navbar">
      <div class="logo"><img src="./assets/img/TopaLogo.png" alt="Logo Toapenda" style="cursor: pointer;" @click="reloadPage"/></div>
      <LanguageSelector :selectedLanguage="currentLanguage" @update:language="changeLanguage" />
  </nav>
  <router-view />
</template>

<script>
import { ref } from 'vue';
import LanguageSelector from './components/LanguageSelector.vue';

export default {
  components: {
    LanguageSelector
  },
  setup() {
    const currentLanguage = ref('eu'); // Valor por defecto del idioma

    // Función para manejar el cambio de idioma
    const changeLanguage = (language) => {
      currentLanguage.value = language; 
    };

    const reloadPage = () => {
      window.location.reload();  // Recargar la página
    };

    return {
      currentLanguage, 
      changeLanguage,
      reloadPage,
    };
  }
};
</script>
