<template>
  <div ref="filterContainer">
    <div class="dropdown" ref="dropdown">
      <button class="dropdown-toggle"
        :class="{ 'selected': filtersApplied }" 
        @click="toggleDropdown">
        {{ $t('towns.title') }}
        <span v-if="!filtersApplied" class="show-filter" :class="{ 'rotated': isOpen }"> <img src="../assets/svg/down.svg" alt="Toggle Filter Icon"></span>
        <span v-if="filtersApplied" @click.stop="resetFilter" class="reset-btn"> <img src="../assets/svg/x.svg" alt="Reset Filter"></span>
      </button>
      <div class="dropdown-menu" :class="{ open: isOpen }" @click.self="isOpen = false" ref="dropdownMenu">
        <div>
          <input 
            type="checkbox" 
            id="all-towns"
            :checked="allTownsSelected"
            @change="onAllTownsChange"
          />
          <label v-if="nroEvents > 0" for="all">{{ $t('all') }}</label>
        </div>
        <div v-if="towns.length > 0">
          <div v-for="town in towns" :key="town.id">
            <input 
              type="checkbox" 
              :id="town.id" 
              :value="town.id" 
              v-model="localSelectedTowns"
              @change="onTownChange(town.id, $event.target.checked)"
            />
            <label :for="town.id">{{ town.name }} ({{ town.count }})</label>
          </div>
        </div>
        <div v-else>
          <p>{{ $t('towns.noTownsFound') }}</p>
        </div>
        <button @click="applyFilter" class="apply-button">
          {{ $t('apply') }}
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, computed, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { filterState } from '../filterState';

export default {
  props: {
    selectedTowns: {
      type: Array,
      default: () => []
    },
    townsResult: {  
      type: Array,
      default: () => []
    },
    numberOfEvents: {
      type: Number
    }
  },
  setup(props, { emit }) {
    const towns = ref([]);
    const localSelectedTowns = ref([...props.selectedTowns]);
    const originalSelectedTowns = ref([...props.selectedTowns]);
    const filtersApplied = ref(false);
    const isOpen = ref(false);
    const dropdown = ref(null);
    const nroEvents = ref(props.numberOfEvents); 
    
    // Computed para obtener los municipios del resultado recibido como prop
    const townsList = computed(() => {
      return props.townsResult || [];
    });

    // Actualizar municipios cuando townsResult cambia
    watch(
      () => props.townsResult,
      (newResult) => {
        if (Array.isArray(newResult) && newResult.length > 0) {
        
          towns.value = newResult;  // Actualizar towns
          nroEvents.value = props.numberOfEvents; 
        
          // Mantener las selecciones previas si no hay filtros aplicados
         if (!filtersApplied.value) {
          //  localSelectedTowns.value = [...props.selectedTowns]; // Mantener las selecciones anteriores
            localSelectedTowns.value = towns.value.map(town => town.id);
          }
        } else {
          console.log('Sin datos válidos en townsResult');
        }
      } ,
      { immediate: true }  
    );

    // Computado para verificar si todos los municipios están seleccionados
    const allTownsSelected = computed(() => {
      return towns.value.length > 0 && localSelectedTowns.value.length === towns.value.length;
    });

    // Manejar cambios en la selección de "Todos"
    const onAllTownsChange = (event) => {
      if (event.target.checked) {
        localSelectedTowns.value = towns.value.map(town => town.id);
      } else {
        localSelectedTowns.value = [];
      }
    };

    // Manejar cambios en municipios individuales
    const onTownChange = (townId, checked) => {
      if (checked) {
        if (!localSelectedTowns.value.includes(townId)) {
          localSelectedTowns.value.push(townId);
        }
      } else {
        localSelectedTowns.value = localSelectedTowns.value.filter(id => id !== townId);
      }
    };

    const dropdownMenu = ref(null);
    // Función para alternar el dropdown
    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        //originalSelectedTowns.value = [...localSelectedTowns.value];
        localSelectedTowns.value = towns.value.map(town => town.id);
        nextTick(() => {
          if (dropdownMenu.value && dropdown.value) {
            const { top, left, width } = dropdown.value.getBoundingClientRect();
            dropdownMenu.value.style.position = 'absolute';
            dropdownMenu.value.style.top = `${top + window.scrollY + dropdown.value.offsetHeight}px`;
            dropdownMenu.value.style.left = `${left + window.scrollX}px`;
            dropdownMenu.value.style.width = `${width}px`;
          } else {
            console.warn("dropdownMenu or dropdown is null");
          }
        });
      } else {
        // Al cerrar, puedes resetear el ancho si lo has cambiado
        if (dropdownMenu.value) {
          dropdownMenu.value.style.width = ''; // Resetear el ancho al valor por defecto
        }
      }
    };

    // Función para manejar clics fuera del dropdown
    const handleClickOutside = (event) => {
      if (dropdown.value && !dropdown.value.contains(event.target)) {
        if (isOpen.value) {
          localSelectedTowns.value = [...originalSelectedTowns.value];
        }
        isOpen.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    const applyFilter = () => {
      filterState.selectedTown = localSelectedTowns.value;
      emit('update:selectedTowns', localSelectedTowns.value);
      filtersApplied.value = localSelectedTowns.value.length === 0 || localSelectedTowns.value.length !== towns.value.length;
      isOpen.value = false; // Cerrar el dropdown después de aplicar el filtro
    };

    const resetFilter = () => {
      localSelectedTowns.value = towns.value.map(town => town.id);
      filterState.selectedTown = null;
      emit('update:selectedTowns', localSelectedTowns.value);
      filtersApplied.value = false;
      isOpen.value = false;
    };

    return {
      towns,
      nroEvents,
      townsList,
      allTownsSelected,
      selectedTown: ref(null), 
      localSelectedTowns,
      onAllTownsChange,
      onTownChange,
      isOpen,
      toggleDropdown,
      applyFilter,
      resetFilter,
      filtersApplied,
      dropdown,
      dropdownMenu,
    };
  }
};
</script>
