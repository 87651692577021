<template>
  <div v-if="isVisible" class="privacity-overlay"  @click="handleClickOutside">
    <div class="privacity-section">
      <div class="privacity-content">
       <span class="close" @click="closeModal">&times;</span>
      <br>
      <h2>{{ $t('privacy.title') }}</h2>
      <p>
        {{ $t('privacy.text1') }}
      </p>
      <p>
        {{ $t('privacy.text2') }}
      </p>
      <p>
        {{ $t('privacy.text3') }}
      </p>
      
    </div>
    
  </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) { // Cambiado para usar destructuring en { emit }
    const closeModal = () => {
      emit('close'); // Emite el evento 'close' al componente padre
    };


    const handleClickOutside = (event) => {
      // Verifica que el clic sea en el overlay y no en el contenido del modal
      if (event.target.classList.contains('privacity-overlay')) {
        closeModal();
      }
    };

    return { 
      closeModal,
      handleClickOutside,
     };
  }
});
</script>
